<template>
  <line-chart
    title="Données par catégories"
    :loading="loading"
    :colors="colors"
    :series="series"
    :yAxis="yAxis"
    :xAxis="xAxis"
    :stroke="stroke"
    outlined
  ></line-chart>
</template>

<script>
import debounce from "lodash/debounce";

import LineChart from "@/components/common/charts/LineChart";

import useAxios from "@/hooks/useAxios";

import { colors } from "@/utils/constants";
import { formatNumber, truncate } from "@/utils/formatting";

const yAxis = (color = "") => ({
  min: 0,
  forceNiceScale: true,
  labels: {
    formatter: (val) => formatNumber(val),
    style: {
      fontSize: "12px",
      fontWeight: 400,
      ...(color ? { colors: color } : {}),
    },
  },
});

export default {
  name: "CategoryCaAndSessionDistributionChart",
  components: {
    LineChart,
  },
  data() {
    return {
      loading: false,
      colors: [colors.sessions, colors.ca],
      series: [
        // Order must be the same than in yaxis definition here below
        {
          name: "sessions",
          data: [],
        },
        {
          name: "ca",
          data: [],
        },
      ],
      yAxis: [
        // Order must be the same than in series definitions
        {
          seriesName: "sessions",
          title: {
            text: "Sessions",
            style: { color: colors.sessions },
          },
          ...yAxis(colors.sessions),
        },
        {
          opposite: true,
          seriesName: "ca",
          title: {
            text: "CA",
            style: { color: colors.ca },
          },
          ...yAxis(colors.ca),
        },
      ],
      xAxis: {
        categories: [],
        labels: {
          rotate: -45,
          formatter: (value) => {
            return truncate(value, 25);
          },
        },
        style: {
          fontSize: "12px",
          fontWeight: 400,
        },
      },
      stroke: {
        show: true,
        curve: "smooth",
        width: [2, 2],
      },
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      if (
        this.applyFilters &&
        !this.loading &&
        this.startDate &&
        this.startDate.length === 10 &&
        this.endDate &&
        this.endDate.length === 10
      ) {
        this.loading = true;
        const {
          data: { items },
        } = await this.axiosGet("/adn-post/categories/performances", {
          start_date: this.startDate,
          end_date: this.endDate,
          period_type: this.periodType,
          sites: this.sites.join(","),
          categories: this.categories.join(","),
          channels: this.channels.join(","),
          page: 1,
          limit: 20,
          sort_by: "sessions",
        });

        const ca_series = [];
        const session_series = [];
        const labels = [];

        for (const { ca, sessions, category } of items) {
          ca_series.push(parseFloat(ca));
          session_series.push(parseInt(sessions, 10));
          labels.push(category);
        }

        this.xAxis = {
          ...this.xAxis,
          categories: labels,
        };

        this.series = [
          // Order must be the same than in chartOptions.yaxis definition
          {
            name: "sessions",
            type: "line",
            data: session_series,
          },
          {
            name: "ca",
            type: "column",
            data: ca_series,
          },
        ];
        this.loading = false;

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        this.$store.dispatch("common/updateApplyFilters", false);
      }
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    categories() {
      return this.$store.getters["audience/getCategories"];
    },
    channels() {
      return this.$store.getters["audience/getChannels"];
    },
    sites() {
      return this.$store.getters["audience/getSites"];
    },
    startDate() {
      return this.$store.getters["audience/getDates"][0];
    },
    endDate() {
      return this.$store.getters["audience/getDates"][1];
    },
    periodType() {
      return this.$store.getters["audience/getPeriodType"];
    },
  },
  watch: {
    startDate() {
      this.debouncedGetData();
    },
    endDate() {
      this.debouncedGetData();
    },
    categories() {
      this.debouncedGetData();
    },
    channels() {
      this.debouncedGetData();
    },
    sites() {
      this.debouncedGetData();
    },
    periodType() {
      this.debouncedGetData();
    },
    applyFilters() {
      this.debouncedGetData();
    },
  },
};
</script>
