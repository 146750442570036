<template>
  <v-autocomplete
    outlined
    v-model="selected"
    :items="channels"
    :menu-props="{ maxHeight: '400', zIndex: 13 }"
    label="Sélectionner les channels"
    multiple
    small-chips
    deletable-chips
  ></v-autocomplete>
</template>

<script>
import debounce from "lodash/debounce";

export default {
  name: "ChannelsFilter",
  data() {
    return {};
  },
  async created() {
    await this.refreshChannelList();
  },
  methods: {
    debouncedRefreshChannelList: debounce(async function () {
      await this.refreshChannelList();
    }, 500),
    async refreshChannelList() {
      if (
        !this.startDate ||
        this.startDate.length !== 10 ||
        !this.endDate ||
        this.endDate.length !== 10
      ) {
        return;
      }

      await this.$store.dispatch("audience/getChannelList", {
        sites: this.sites.join(","),
        categories: this.categories.join(","),
        start_date: this.startDate,
        end_date: this.endDate,
        period_type: this.periodType,
      });
    },
  },
  computed: {
    selected: {
      get() {
        return this.$store.getters["audience/getChannels"];
      },
      set(e) {
        this.$store.dispatch("audience/updateChannels", e);
      },
    },
    categories() {
      return this.$store.getters["audience/getCategories"];
    },
    sites() {
      return this.$store.getters["audience/getSites"];
    },
    startDate() {
      return this.$store.getters["audience/getDates"][0];
    },
    endDate() {
      return this.$store.getters["audience/getDates"][1];
    },
    periodType() {
      return this.$store.getters["audience/getPeriodType"];
    },
    channels() {
      return this.$store.getters["audience/getChannelList"];
    },
  },
  watch: {
    async categories() {
      await this.debouncedRefreshChannelList();
    },
    async sites() {
      await this.debouncedRefreshChannelList();
    },
    async startDate() {
      await this.debouncedRefreshChannelList();
    },
    async endDate() {
      await this.debouncedRefreshChannelList();
    },
    async periodType() {
      await this.debouncedRefreshChannelList();
    },
  },
};
</script>

<style scoped>
[role="menu"] {
  top: 233px !important;
  right: 0 !important;
}
</style>
